import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";

import "assets/scss/elite-feats-cms.scss?v=1.9.0";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "../src/Language/i18n.js";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Suspense fallback={<div>Loading...</div>}>
      <Elements stripe={stripePromise}>
        <Switch>
          <Route path="/rtl" component={RtlLayout} />
          <Route path="/auth" component={AuthLayout} />
          <Route path="/admin" component={AdminLayout} />

          <Redirect from="/" to="/admin/dashboard" />
        </Switch>
      </Elements>
    </Suspense>
  </Router>,
  document.getElementById("root")
);
