import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";

// material-ui icons
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/elite-feats-cms/views/extendedTablesStyle.js";

import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function ExtendedTables(props) {
  const { t } = useTranslation();
  let assignment = JSON.parse(localStorage.getItem("assignment"));
  let order = JSON.parse(localStorage.getItem("order"));
  const {
    totalPurchased,
    totalUsed,
    pending,
    completed,
    subscriptionData,
  } = props;
  const cartContext = React.useContext(AppContext);
  let initialChecked = [];
  for (let number in assignment) {
    if (assignment[number] === true) {
      initialChecked.push(number);
    }
  }

  const [checked, setChecked] = useState(initialChecked);
  const [subscriptionAvailable, setSubscriptionAvailable] = useState(0);
  const [loading, setLoading] = useState(true);
  const [effectiveAvailableUnits, setEffectiveAvailableUnits] = useState({
    tool2_1: totalPurchased.tool2_1 - totalUsed.tool2_1,
    tool2_2: totalPurchased.tool2_2 - totalUsed.tool2_2,
    tool2_3: totalPurchased.tool2_3 - totalUsed.tool2_3,
  });

  // Fetch subscription data
  useEffect(() => {
    try {
      setLoading(true);

      const availableUnits =
        subscriptionData && subscriptionData.active
          ? subscriptionData.totalAvailable
          : 0;

      setSubscriptionAvailable(availableUnits);

      // Update the effective available units
      setEffectiveAvailableUnits({
        tool2_1:
          totalPurchased.tool2_1 -
          totalUsed.tool2_1 +
          (availableUnits > 0 ? 1 : 0),
        tool2_2:
          totalPurchased.tool2_2 -
          totalUsed.tool2_2 +
          (availableUnits > 0 ? 1 : 0),
        tool2_3:
          totalPurchased.tool2_3 -
          totalUsed.tool2_3 +
          (availableUnits > 0 ? 1 : 0),
      });
    } catch (error) {
      console.error("Error fetching subscription data:", error);
      // Fallback to just purchased - used
      setEffectiveAvailableUnits({
        tool2_1: totalPurchased.tool2_1 - totalUsed.tool2_1,
        tool2_2: totalPurchased.tool2_2 - totalUsed.tool2_2,
        tool2_3: totalPurchased.tool2_3 - totalUsed.tool2_3,
      });
    } finally {
      setLoading(false);
    }
  }, [totalPurchased, totalUsed]);

  useEffect(() => {
    if (!order.length && checked.length) {
      setChecked([]);
    }
  });

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value.toString());
    const orderIndex = order.indexOf(value.toString());
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value.toString());
      order.push(value.toString());
      assignment[`${value}`] = true;
    } else {
      newChecked.splice(currentIndex, 1);
      order.splice(orderIndex, 1);
      assignment[`${value}`] = false;
    }
    setChecked(newChecked);
    localStorage.setItem("assignment", JSON.stringify(assignment));
    localStorage.setItem("order", JSON.stringify(order));
    props.triggerReload();
  };

  const classes = useStyles();
  const fillButtons = [
    { color: "info", icon: Person },
    { color: "success", icon: Edit },
    { color: "danger", icon: Close },
  ].map((prop, key) => {
    return (
      <Button color={prop.color} className={classes.actionButton} key={key}>
        <prop.icon className={classes.icon} />
      </Button>
    );
  });

  const simpleButtons = [
    { color: "info", icon: Person },
    { color: "success", icon: Edit },
    { color: "danger", icon: Close },
  ].map((prop, key) => {
    return (
      <Button
        color={prop.color}
        simple
        className={classes.actionButton}
        key={key}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });

  const roundButtons = [
    { color: "info", icon: Person },
    { color: "success", icon: Edit },
    { color: "danger", icon: Close },
  ].map((prop, key) => {
    return (
      <Button
        round
        color={prop.color}
        className={classes.actionButton + " " + classes.actionButtonRound}
        key={key}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card plain style={{ marginTop: -10, marginBottom: 0 }}>
          <CardBody>
            <Table
              tableHead={[
                t("assignments:tableHeaders.assessment"),
                t("assignments:tableHeaders.purchased"),
                t("assignments:tableHeaders.used"),
                t("assignments:tableHeaders.available"),
                t("assignments:tableHeaders.pending"),
                t("assignments:tableHeaders.completed"),
                t("assignments:tableHeaders.assign"),
              ]}
              tableData={[
                [
                  t("assignments:assessmentNames.tool2_1"),
                  `${totalPurchased.tool2_1}`,
                  `${totalUsed.tool2_1}`,
                  `${totalPurchased.tool2_1 - totalUsed.tool2_1}${
                    subscriptionAvailable > 0
                      ? " + " + subscriptionAvailable
                      : ""
                  }`,
                  `${pending.tool2_1}`,
                  `${completed.tool2_1}`,
                  effectiveAvailableUnits.tool2_1 > 0 ? (
                    <Checkbox
                      key="key"
                      tabIndex={-1}
                      style={{ padding: 7 }}
                      onClick={() => handleToggle(2.1)}
                      checked={assignment["2.1"]}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checkedYellow,
                        root: classes.checkRoot,
                      }}
                    />
                  ) : (
                    <Button
                      color="warning"
                      round
                      size="sm"
                      style={{ padding: 4, paddingLeft: 15, paddingRight: 15 }}
                      onClick={() => {
                        let currentCart = JSON.parse(
                          localStorage.getItem("cart")
                        );
                        currentCart["tool2_1"] += 1;
                        localStorage.setItem(
                          "cart",
                          JSON.stringify(currentCart)
                        );
                        cartContext.setCart(currentCart);
                      }}
                    >
                      {t("assignments:addToCart")}
                    </Button>
                  ),
                ],
                [
                  t("assignments:assessmentNames.tool2_2"),
                  `${totalPurchased.tool2_2}`,
                  `${totalUsed.tool2_2}`,
                  `${totalPurchased.tool2_2 - totalUsed.tool2_2}${
                    subscriptionAvailable > 0
                      ? " + " + subscriptionAvailable
                      : ""
                  }`,
                  `${pending.tool2_2}`,
                  `${completed.tool2_2}`,
                  effectiveAvailableUnits.tool2_2 > 0 ? (
                    <Checkbox
                      key="key"
                      tabIndex={-1}
                      style={{ padding: 7 }}
                      onClick={() => handleToggle(2.2)}
                      checked={assignment["2.2"]}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checkedYellow,
                        root: classes.checkRoot,
                      }}
                    />
                  ) : (
                    <Button
                      color="warning"
                      round
                      size="sm"
                      style={{ padding: 4, paddingLeft: 15, paddingRight: 15 }}
                      onClick={() => {
                        let currentCart = JSON.parse(
                          localStorage.getItem("cart")
                        );
                        currentCart["tool2_2"] += 1;
                        localStorage.setItem(
                          "cart",
                          JSON.stringify(currentCart)
                        );
                        cartContext.setCart(currentCart);
                      }}
                    >
                      {t("assignments:addToCart")}
                    </Button>
                  ),
                ],
                [
                  t("assignments:assessmentNames.tool2_3"),
                  `${totalPurchased.tool2_3}`,
                  `${totalUsed.tool2_3}`,
                  `${totalPurchased.tool2_3 - totalUsed.tool2_3}${
                    subscriptionAvailable > 0
                      ? " + " + subscriptionAvailable
                      : ""
                  }`,
                  `${pending.tool2_3}`,
                  `${completed.tool2_3}`,
                  effectiveAvailableUnits.tool2_3 > 0 ? (
                    <Checkbox
                      key="key"
                      tabIndex={-1}
                      style={{ padding: 7 }}
                      onClick={() => handleToggle(2.3)}
                      checked={assignment["2.3"]}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checkedYellow,
                        root: classes.checkRoot,
                      }}
                    />
                  ) : (
                    <Button
                      color="warning"
                      round
                      size="sm"
                      style={{ padding: 4, paddingLeft: 15, paddingRight: 15 }}
                      onClick={() => {
                        let currentCart = JSON.parse(
                          localStorage.getItem("cart")
                        );
                        currentCart["tool2_3"] += 1;
                        localStorage.setItem(
                          "cart",
                          JSON.stringify(currentCart)
                        );
                        cartContext.setCart(currentCart);
                      }}
                    >
                      {t("assignments:addToCart")}
                    </Button>
                  ),
                ],
              ]}
              customCellClasses={[
                classes.left,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
              ]}
              customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
              customHeadCellClasses={[
                classes.left,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
              ]}
              customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
