import React from "react";
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import SvgIcon from "@material-ui/core/SvgIcon";
import MUICard from "@material-ui/core/Card";
import MUICardHeader from "@material-ui/core/CardHeader";
import MUITypography from "@material-ui/core/Typography";
// @material-ui/icons
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { ReactComponent as Tool1 } from "assets/icons/tool1.svg";
import { ReactComponent as Tool2 } from "assets/icons/tool2.svg";
import { ReactComponent as Tool3 } from "assets/icons/tool3.svg";
import { ReactComponent as Tool4 } from "assets/icons/tool4.svg";
import { ReactComponent as Tool5 } from "assets/icons/tool5.svg";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";
// import ContentCopy from "@material-ui/icons/ContentCopy";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Typo from "@material-ui/core/Typography";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomTabs from "components/CustomTabs/CustomTabsAssign.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import ModTab from "../Tables/ModulesTable";
import Tool1Tab from "../Tables/Tools1Table";
import Tool2Tab from "../Tables/Tools2Table";
import Tool3Tab from "../Tables/Tools3Table";
import Tool4Tab from "../Tables/Tools4Table";
import Tool5Tab from "../Tables/Tools5Table";

import allStyles from "assets/jss/elite-feats-cms/views/dashboardStyle.js";

import { withRouter } from "react-router-dom";
import axios from "axios";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/FadeLoader";
import Typography from "components/Typography/Muted.js";

import AssignLoading from "../Dashboard/AssignLoading";
import CheckLoading from "../Dashboard/CheckLoading";

import AllAssigned from "../Clients/Modals/AllAssigned";

import identifiers from "./identifiers";
import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import assessmentTitleNumbers from "../Dashboard/assessmentTitleNumbers";
import Tour from "../Dashboard/assessmentIntro";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";
import { set } from "mongoose";

const styles = {
  ...allStyles,
  ...alertStyles,
};

const useStyles = makeStyles(styles);

function AssignAssessments(props) {
  const { t, i18n } = useTranslation();
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const {
    totalPurchased,
    totalUsed,
    width,
    grow,
    loading,
    order,
    height,
    completed,
    pending,
    triggerReload,
  } = props;

  const ModulesIcon = () => <SvgIcon component={Module} />;
  const Tool1Icon = () => <SvgIcon component={Tool1} />;
  const Tool2Icon = () => <SvgIcon component={Tool2} />;
  const Tool3Icon = () => <SvgIcon component={Tool3} />;
  const Tool4Icon = () => <SvgIcon component={Tool4} />;
  const Tool5Icon = () => <SvgIcon component={Tool5} />;
  const classes = useStyles();

  const [alert, setAlert] = React.useState(null);

  //Assign assessment logic

  const [error, setError] = React.useState([]);
  const [openAssignLoading, setOpenAssignLoading] = React.useState(false);
  const [openCheckLoading, setOpenCheckLoading] = React.useState(false);
  const [subscriptionData, setSubscriptionData] = React.useState({});
  const [loadingSubscription, setLoadingSubscription] = React.useState(false);
  const [
    triggerRefetchSubscription,
    setTriggerRefetchSubscription,
  ] = React.useState(null);
  const [assignData, setassignData] = React.useState({
    errors: {},
    success: {},
  });

  const [tourRun, setTourRun] = React.useState(false);
  const [triggerStep, setTriggerStep] = React.useState(-1);

  const firstRender = React.useRef(true);

  const getTranslationKey = (id) => {
    return id.toString().includes(".")
      ? `assignments:tools.${id}`
      : `assignments:modules.${id}`;
  };

  const hideAlert = (loc) => {
    if (loc === "success") {
      setAlert(null);
      localStorage.setItem("order", JSON.stringify([]));
      let assignments = JSON.parse(localStorage.getItem("assignment"));
      for (const assignment in assignments) {
        assignments[assignment] = false;
      }
      localStorage.setItem("assignment", JSON.stringify(assignments));

      props.triggerReload();
      localStorage.setItem("selected_clients", JSON.stringify([]));
      props.setReloadClients(Math.random());
      props.assessmentTrigger(Math.random());
    }
    if (loc === "unsuccessful") {
      setAlert(null);

      props.setReloadClients(Math.random());
    }
    setOpenAssignLoading(false);
    setAlert(null);
  };

  const confirmationAlert = () => {
    let assessments = JSON.parse(localStorage.getItem("order"));
    let clients = JSON.parse(localStorage.getItem("selected_clients"));
    if (JSON.parse(localStorage.getItem("order")).length) {
      let assignment = JSON.parse(localStorage.getItem("assignment"));

      // Calculate available assessments (purchased + subscription)
      let totalAvailable = {};
      let needsSubscriptionUnits = false;
      let assessmentsNeedingSubscription = [];

      for (let assessment in props.totalUsed) {
        // Base availability from purchased assessments
        const purchasedAvailable =
          props.totalPurchased[assessment] - props.totalUsed[assessment];
        totalAvailable[`${assessment}`] = purchasedAvailable;

        // Check if we need subscription units for any selected assessment
        for (let assessmentNo in assignment) {
          if (
            assignment[assessmentNo] === true &&
            identifiers[assessmentNo] === assessment
          ) {
            // Get number of clients to check if purchased units are enough
            const numberOfSelectedClients =
              JSON.parse(localStorage.getItem("selected_clients")).length ||
              (
                JSON.parse(localStorage.getItem("selected_groups")) || []
              ).reduce((total, group) => total + group.members.length, 0);

            if (purchasedAvailable < numberOfSelectedClients) {
              // We'll need subscription units for this assessment
              needsSubscriptionUnits = true;
              if (!assessmentsNeedingSubscription.includes(assessment)) {
                assessmentsNeedingSubscription.push(assessment);
              }
            }
          }
        }

        // Add subscription units if available
        if (
          subscriptionData &&
          subscriptionData.active &&
          subscriptionData.totalAvailable > 0
        ) {
          // We count subscription units as available for all assessment types
          totalAvailable[`${assessment}`] += subscriptionData.totalAvailable;
        }
      }

      let numberOfClients = 0;
      if (JSON.parse(localStorage.getItem("selected_clients")).length) {
        numberOfClients = JSON.parse(localStorage.getItem("selected_clients"))
          .length;
      } else if (JSON.parse(localStorage.getItem("selected_groups")).length) {
        let groups = JSON.parse(localStorage.getItem("selected_groups"));
        groups.forEach((group) => {
          numberOfClients += group.members.length;
        });
      }

      if (numberOfClients > 0) {
        let checkAssignment = {};
        for (let assessmentNo in assignment) {
          if (assignment[assessmentNo] === true) {
            checkAssignment[`${identifiers[assessmentNo]}`] =
              totalAvailable[identifiers[assessmentNo]];
          }
        }

        let deficit = [];

        for (let assessment in checkAssignment) {
          if (checkAssignment[assessment] < numberOfClients) {
            deficit.push(assessment);
          }
        }

        if (deficit.length) {
          setError(deficit);
        } else {
          setOpenCheckLoading(true);
          if (JSON.parse(localStorage.getItem("selected_clients")).length) {
            axios
              .post("/api/client/get-names", clients, {
                headers: {
                  "auth-token": localStorage.getItem("token"),
                },
              })
              .then((res) => {
                setOpenCheckLoading(false);
                setAlert(
                  <SweetAlert
                    showCancel
                    confirmBtnText={t("assignments:confirm")}
                    cancelBtnCssClass={`${classes.button} ${classes.warning}`}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={t("assignments:alerts.reviewAndConfirm")}
                    onConfirm={() => {
                      handleAssignment();
                      hideAlert();
                    }}
                    onCancel={() => hideAlert()}
                    confirmBtnCssClass={`${classes.button} ${classes.success}`}
                  >
                    <MUITypography align="left">
                      {t("assignments:alerts.followingAssessmentsAssigned")}
                    </MUITypography>

                    {/* Subscription notice */}
                    {needsSubscriptionUnits &&
                      subscriptionData &&
                      subscriptionData.active && (
                        <MUITypography
                          align="left"
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                            backgroundColor: "#e8f5e9",
                            padding: 10,
                            borderRadius: 4,
                            color: "#2e7d32",
                            fontSize: 14,
                          }}
                        >
                          <b>
                            {t("assignments:subscriptionNotice.title") ||
                              "Note:"}
                          </b>{" "}
                          {t("assignments:subscriptionNotice.unitsUsed") ||
                            "Subscription units will be used for some assessments."}
                          {subscriptionData.totalAvailable > 1
                            ? t(
                                "assignments:subscriptionNotice.multipleUnits",
                                { count: subscriptionData.totalAvailable }
                              ) ||
                              ` You have ${subscriptionData.totalAvailable} subscription units available.`
                            : t("assignments:subscriptionNotice.singleUnit") ||
                              " You have 1 subscription unit available."}
                          {/* Show cancellation status if subscription is canceled */}
                          {subscriptionData.cancelAtPeriodEnd &&
                            subscriptionData.expirationDate && (
                              <div
                                style={{
                                  marginTop: 5,
                                  borderTop: "1px dashed #2e7d32",
                                  paddingTop: 5,
                                }}
                              >
                                <b>
                                  {t(
                                    "assignments:subscriptionNotice.canceled"
                                  ) || "Subscription Canceled:"}
                                </b>{" "}
                                {t(
                                  "assignments:subscriptionNotice.accessUntilPrefix"
                                ) || "Access until"}{" "}
                                {new Date(
                                  subscriptionData.expirationDate
                                ).toLocaleDateString(i18n.language || "en-US", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  timeZone: "UTC",
                                })}
                              </div>
                            )}
                          {assessmentsNeedingSubscription.length > 0 && (
                            <div style={{ marginTop: 5 }}>
                              <span>
                                {t(
                                  "assignments:subscriptionNotice.assessmentsUsing"
                                ) ||
                                  "Assessments using subscription units:"}{" "}
                              </span>
                              {assessmentsNeedingSubscription.map(
                                (assessment, index) => (
                                  <span key={index}>
                                    {t(
                                      `assignments:assessmentNames.${assessment}`
                                    )}
                                    {index ===
                                    assessmentsNeedingSubscription.length - 1
                                      ? ", "
                                      : ""}
                                  </span>
                                )
                              )}
                            </div>
                          )}
                        </MUITypography>
                      )}
                    <Grid
                      container
                      style={{
                        maxHeight: 300,
                        overflow: "auto",
                        border: "1px solid #dedede",
                        borderRadius: 5,
                        marginTop: 10,
                        padding: 10,
                        paddingBottom: 20,
                      }}
                    >
                      <MUITypography align="left" style={{ fontWeight: 600 }}>
                        {t("assignments:assessments")}
                      </MUITypography>
                      <Grid
                        container
                        style={{
                          border: "1px solid #dedede",
                          borderRadius: 5,
                          marginTop: 2,
                          padding: 10,
                          paddingBottom: 20,
                        }}
                      >
                        {assessments.map((id, index) => (
                          <Grid item style={{ marginRight: 5 }} key={index}>
                            {index !== assessments.length - 1 ? (
                              <MUITypography style={{ fontSize: 15 }}>
                                {`${t(getTranslationKey(id))}, `}
                              </MUITypography>
                            ) : (
                              <MUITypography style={{ fontSize: 15 }}>
                                {`${t(getTranslationKey(id))} `}
                              </MUITypography>
                            )}
                          </Grid>
                        ))}
                      </Grid>
                      <MUITypography
                        align="left"
                        style={{ fontWeight: 600, marginTop: 15 }}
                      >
                        {t("assignments:clients")}
                      </MUITypography>
                      <Grid
                        container
                        style={{
                          border: "1px solid #dedede",
                          borderRadius: 5,
                          marginTop: 2,
                        }}
                      >
                        <MUICard elevation={0} style={{ width: "100%" }}>
                          {res.data.map((client, index) => (
                            <React.Fragment key={index}>
                              <MUITypography
                                align="left"
                                style={{
                                  fontSize: 15,
                                  padding: 5,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                }}
                              >
                                {client}
                              </MUITypography>
                              {index !== res.data.length - 1 ? (
                                <Divider />
                              ) : null}
                            </React.Fragment>
                          ))}
                        </MUICard>
                      </Grid>
                    </Grid>
                  </SweetAlert>
                );
              });
          } else if (
            JSON.parse(localStorage.getItem("selected_groups")).length
          ) {
            setOpenCheckLoading(false);
            setAlert(
              <SweetAlert
                showCancel
                confirmBtnText="Confirm"
                cancelBtnCssClass={classes.button + " " + classes.warning}
                style={{ display: "block", marginTop: "-100px" }}
                title="Please Review and Confirm This Operation"
                onConfirm={() => {
                  handleAssignment();
                  hideAlert();
                }}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
              >
                <MUITypography align="left">
                  The following assessments will be assigned to the indicated
                  groups:
                </MUITypography>

                {/* Subscription notice for groups */}
                {needsSubscriptionUnits &&
                  subscriptionData &&
                  subscriptionData.active && (
                    <MUITypography
                      align="left"
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        backgroundColor: "#e8f5e9",
                        padding: 10,
                        borderRadius: 4,
                        color: "#2e7d32",
                        fontSize: 14,
                      }}
                    >
                      <b>
                        {t("assignments:subscriptionNotice.title") || "Note:"}
                      </b>{" "}
                      {t("assignments:subscriptionNotice.unitsUsed") ||
                        "Subscription units will be used for some assessments."}
                      {subscriptionData.totalAvailable > 1
                        ? t("assignments:subscriptionNotice.multipleUnits", {
                            count: subscriptionData.totalAvailable,
                          }) ||
                          ` You have ${subscriptionData.totalAvailable} subscription units available.`
                        : t("assignments:subscriptionNotice.singleUnit") ||
                          " You have 1 subscription unit available."}
                      {/* Show cancellation status if subscription is canceled */}
                      {subscriptionData.cancelAtPeriodEnd &&
                        subscriptionData.expirationDate && (
                          <div
                            style={{
                              marginTop: 5,
                              borderTop: "1px dashed #2e7d32",
                              paddingTop: 5,
                            }}
                          >
                            <b>
                              {t("assignments:subscriptionNotice.canceled") ||
                                "Subscription Canceled:"}
                            </b>{" "}
                            {t(
                              "assignments:subscriptionNotice.accessUntilPrefix"
                            ) || "Access until"}{" "}
                            {new Date(
                              subscriptionData.expirationDate
                            ).toLocaleDateString(i18n.language || "en-US", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              timeZone: "UTC",
                            })}
                          </div>
                        )}
                      {assessmentsNeedingSubscription.length > 0 && (
                        <div style={{ marginTop: 5 }}>
                          <span>
                            {t(
                              "assignments:subscriptionNotice.assessmentsUsing"
                            ) || "Assessments using subscription units:"}{" "}
                          </span>
                          {assessmentsNeedingSubscription.map(
                            (assessment, index) => (
                              <span key={index}>
                                {t(`assignments:assessmentNames.${assessment}`)}
                                {index ===
                                assessmentsNeedingSubscription.length - 1
                                  ? ", "
                                  : ""}
                              </span>
                            )
                          )}
                        </div>
                      )}
                    </MUITypography>
                  )}

                <Grid
                  container
                  style={{
                    maxHeight: 300,
                    overflow: "auto",
                    border: "1px solid #dedede",
                    borderRadius: 5,
                    marginTop: 10,
                    padding: 10,
                    paddingBottom: 20,
                  }}
                >
                  <MUITypography align="left" style={{ fontWeight: 600 }}>
                    Assessments:
                  </MUITypography>
                  <Grid
                    container
                    style={{
                      border: "1px solid #dedede",
                      borderRadius: 5,
                      marginTop: 2,
                      padding: 10,
                      paddingBottom: 20,
                    }}
                  >
                    {assessments.map((id, index) => (
                      <Grid item style={{ marginRight: 5 }} key={index}>
                        {index !== assessments.length - 1 ? (
                          <MUITypography style={{ fontSize: 15 }}>
                            {` ${assessmentTitleNumbers[id]}, `}
                          </MUITypography>
                        ) : (
                          <MUITypography style={{ fontSize: 15 }}>
                            {` ${assessmentTitleNumbers[id]} `}
                          </MUITypography>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                  <MUITypography
                    align="left"
                    style={{ fontWeight: 600, marginTop: 15 }}
                  >
                    Groups:
                  </MUITypography>
                  <Grid
                    container
                    style={{
                      border: "1px solid #dedede",
                      borderRadius: 5,
                      marginTop: 2,
                    }}
                  >
                    <MUICard elevation={0} style={{ width: "100%" }}>
                      {JSON.parse(localStorage.getItem("selected_groups")).map(
                        (group, index) => {
                          return (
                            <React.Fragment key={index}>
                              <MUITypography
                                align="left"
                                style={{
                                  fontSize: 15,
                                  padding: 5,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                }}
                              >
                                {group.name}
                              </MUITypography>
                              {index !==
                              JSON.parse(
                                localStorage.getItem("selected_groups")
                              ).length -
                                1 ? (
                                <Divider />
                              ) : (
                                false
                              )}
                            </React.Fragment>
                          );
                        }
                      )}
                    </MUICard>
                  </Grid>
                </Grid>
              </SweetAlert>
            );
          }
        }
      } else {
        noClientAlert();
      }
    } else {
      noAssignmentAlert();
    }
  };

  const noClientAlert = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("assignments:alerts.noClientsSelected")}
        onConfirm={() => hideAlert()}
        confirmBtnCssClass={`${classes.button} ${classes.warning}`}
        confirmBtnText={t("assignments:ok")}
      >
        {t("assignments:alerts.selectAtLeastOneClient")}
      </SweetAlert>
    );
  };

  const noAssignmentAlert = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("assignments:alerts.noAssessmentsSelected")}
        onConfirm={() => hideAlert()}
        confirmBtnCssClass={`${classes.button} ${classes.warning}`}
        confirmBtnText={t("assignments:ok")}
      >
        {t("assignments:alerts.selectAtLeastOneAssessment")}
      </SweetAlert>
    );
  };

  const dangerAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("assignments:alerts.unableToAssignAssessments")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={`${classes.button} ${classes.danger}`}
      >
        {t("assignments:alerts.insufficientQuantityMessage")}
        <MUICard
          variant="outlined"
          style={{ marginTop: 15, maxHeight: 300, overflow: "auto" }}
        >
          {error.map((assessment, index) => (
            <React.Fragment key={index}>
              <MUITypography
                align="left"
                style={{
                  padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  fontSize: 14,
                }}
              >
                {t(`assignments:assessmentNames.${assessment}`)}
              </MUITypography>
              {index !== error.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </MUICard>
      </SweetAlert>
    );
  };

  const successWithErrorsAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("assignments:alerts.operationCompletedWithErrors")}
        onConfirm={() => errorAlert()}
        onCancel={() => hideAlert("success")}
        confirmBtnCssClass={`${classes.button} ${classes.warning}`}
        confirmBtnText={t("assignments:viewErrors")}
        cancelBtnCssClass={`${classes.button} ${classes.success}`}
        cancelBtnText={t("assignments:ok")}
      >
        {t("assignments:alerts.assessmentsAssignedToClients")}
        <Grid
          container
          style={{
            maxHeight: 300,
            overflow: "auto",
            border: "1px solid #dedede",
            borderRadius: 5,
            marginTop: 10,
            padding: 10,
            paddingBottom: 20,
          }}
        >
          {Object.keys(assignData.success).map((assessment, i) => {
            let details = assignData.success[assessment];
            return (
              <MUICard
                variant="outlined"
                style={{ width: "100%", marginTop: 10 }}
                key={i}
              >
                <MUICardHeader
                  title={
                    <MUITypography
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      {t(`assignments:assessmentNames.${assessment}`)}
                    </MUITypography>
                  }
                  style={{
                    padding: 5,
                    borderBottom: "1px solid #dedede",
                    background: "#23934b",
                  }}
                />
                {details.map((client, index) => (
                  <React.Fragment key={index}>
                    <MUITypography>{client}</MUITypography>
                    {index !== details.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </MUICard>
            );
          })}
        </Grid>
      </SweetAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("assignments:alerts.operationCompletedSuccessfully")}
        onConfirm={() => hideAlert("success")}
        onCancel={() => hideAlert("success")}
        confirmBtnCssClass={`${classes.button} ${classes.success}`}
      >
        {t("assignments:alerts.assessmentsAssignedClients")}
        <Grid
          container
          style={{
            maxHeight: 300,
            overflow: "auto",
            border: "1px solid #dedede",
            borderRadius: 5,
            marginTop: 10,
            padding: 10,
            paddingBottom: 20,
          }}
        >
          {Object.keys(assignData.success).map((assessment, index) => (
            <MUICard
              variant="outlined"
              style={{ width: "100%", marginTop: 10 }}
              key={index}
            >
              <MUICardHeader
                title={
                  <MUITypography style={{ color: "#fff", fontWeight: "bold" }}>
                    {t(`assignments:assessmentNames.${assessment}`)}
                  </MUITypography>
                }
                style={{
                  padding: 5,
                  borderBottom: "1px solid #dedede",
                  background: "#23934b",
                }}
              />
              {assignData.success[assessment].map((client, idx) => (
                <React.Fragment key={idx}>
                  <MUITypography>{client}</MUITypography>
                  {idx !== assignData.success[assessment].length - 1 && (
                    <Divider />
                  )}
                </React.Fragment>
              ))}
            </MUICard>
          ))}
        </Grid>
      </SweetAlert>
    );
  };

  const errorAlert = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("assignments:alerts.assignmentError")}
        onConfirm={() => hideAlert("success")}
        confirmBtnCssClass={`${classes.button} ${classes.warning}`}
        confirmBtnText={t("common:ok")}
      >
        {t("assignments:alerts.assessmentAssignmentIssues")}
        <Grid
          container
          style={{
            maxHeight: 300,
            overflow: "auto",
            border: "1px solid #dedede",
            borderRadius: 5,
            marginTop: 10,
            padding: 10,
            paddingBottom: 20,
          }}
        >
          {Object.keys(assignData.errors).map((assessment, index) => (
            <MUICard
              variant="outlined"
              style={{ width: "100%", marginTop: 10 }}
              key={index}
            >
              <MUICardHeader
                title={
                  <MUITypography style={{ color: "#fff", fontWeight: "bold" }}>
                    {t(`assignments:assessmentNames.${assessment}`)}
                  </MUITypography>
                }
                style={{
                  padding: 5,
                  borderBottom: "1px solid #dedede",
                  background: "#fcb316",
                }}
              />
              {assignData.errors[assessment].map((client, idx) => (
                <React.Fragment key={idx}>
                  <MUITypography>{client}</MUITypography>
                  {idx !== assignData.errors[assessment].length - 1 && (
                    <Divider />
                  )}
                </React.Fragment>
              ))}
            </MUICard>
          ))}
        </Grid>
      </SweetAlert>
    );
  };

  const errorAlert2 = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("assignments:alerts.assignmentError")}
        onConfirm={() => hideAlert("unsuccessful")}
        confirmBtnCssClass={`${classes.button} ${classes.warning}`}
        confirmBtnText={t("common:ok")}
      >
        {t("assignments:alerts.pendingAssignmentError")}
        <Grid
          container
          style={{
            maxHeight: 300,
            overflow: "auto",
            border: "1px solid #dedede",
            borderRadius: 5,
            marginTop: 10,
            padding: 10,
            paddingBottom: 20,
          }}
        >
          {Object.keys(assignData.errors).map((assessment, index) => (
            <MUICard
              variant="outlined"
              style={{ width: "100%", marginTop: 10 }}
              key={index}
            >
              <MUICardHeader
                title={
                  <MUITypography style={{ color: "#fff", fontWeight: "bold" }}>
                    {t(`assignments:assessmentNames.${assessment}`)}
                  </MUITypography>
                }
                style={{
                  padding: 5,
                  borderBottom: "1px solid #dedede",
                  background: "#fcb316",
                }}
              />
              {assignData.errors[assessment].map((client, idx) => (
                <React.Fragment key={idx}>
                  <MUITypography>{client}</MUITypography>
                  {idx !== assignData.errors[assessment].length - 1 && (
                    <Divider />
                  )}
                </React.Fragment>
              ))}
            </MUICard>
          ))}
        </Grid>
      </SweetAlert>
    );
  };

  const handleAssignment = useDebouncedCallback(() => {
    let assignment = JSON.parse(localStorage.getItem("order"));
    if (JSON.parse(localStorage.getItem("selected_clients")).length > 0) {
      let selected = JSON.parse(localStorage.getItem("selected_clients"));
      let to_send = {
        assignment: assignment,
        selected: selected,
        language: i18n.language,
      };
      setOpenAssignLoading(true);
      axios
        .post("/api/client/assign", to_send, {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          setOpenAssignLoading(false);

          localStorage.setItem("selected_clients", JSON.stringify([]));
          props.setReloadClients(Math.random());
          props.setReloadTable(Math.random());
          props.setReloadGroups(Math.random());
          setassignData(res.data);
        });
    }
    if (JSON.parse(localStorage.getItem("selected_groups")).length > 0) {
      let selected = JSON.parse(localStorage.getItem("selected_groups"));
      let to_send = {
        assignment: assignment,
        selected: selected,
        language: i18n.language,
      };
      setOpenAssignLoading(true);
      axios
        .post("/api/client/assign-to-group", to_send, {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          setOpenAssignLoading(false);

          localStorage.setItem("selected_groups", JSON.stringify([]));
          props.setReloadClients(Math.random());
          props.setReloadTable(Math.random());
          props.setReloadGroups(Math.random());
          setassignData(res.data);
        });
    }
  }, 1000);

  React.useEffect(() => {
    setLoadingSubscription(true);
    axios
      .get("/api/client/get-subscription-info", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data) {
          setSubscriptionData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingSubscription(false);
      });
  }, [triggerRefetchSubscription]);

  React.useEffect(() => {
    if (error.length) {
      dangerAlert();
    }
  }, [error]);

  React.useEffect(() => {
    if (!firstRender.current) {
      if (!Object.values(assignData.success).length) {
        errorAlert2();
      } else if (Object.values(assignData.errors).length) {
        successWithErrorsAlert();
        props.setReloadClients(Math.random());
      } else if (Object.values(assignData.success).length) {
        successAlert();
        props.setReloadClients(Math.random());
      }
      setTriggerRefetchSubscription(Math.random());
    } else {
      firstRender.current = false;
    }
  }, [assignData]);

  const clearAll = () => {
    localStorage.setItem("order", JSON.stringify([]));
    let assignments = JSON.parse(localStorage.getItem("assignment"));
    for (const assignment in assignments) {
      assignments[assignment] = false;
    }
    localStorage.setItem("assignment", JSON.stringify(assignments));

    props.triggerReload();
    props.setReloadClients(Math.random());
  };
  return (
    <div>
      {/* Assign assessment Table  */}
      <Tour
        triggerStep={triggerStep}
        setTourRun={setTourRun}
        tourRun={tourRun}
        setTriggerStep={setTriggerStep}
      />
      <GridContainer>
        <div
          style={{
            width: `${width}`,

            transition: "width 0.4s",
          }}
        >
          <GridItem id={"assessTable"}>
            <CustomTabs
              triggerStep={triggerStep}
              setTriggerStep={setTriggerStep}
              setTourRun={setTourRun}
              tourRun={tourRun}
              triggerReload={() => triggerReload()}
              clearAll={() => clearAll()}
              triggerRefetchSubscription={triggerRefetchSubscription}
              tabs={[
                {
                  tabName: t("assignments:tabs.modules"),
                  tabIcon: ModulesIcon,
                  tabContent: !loading ? (
                    <ModTab
                      totalPurchased={totalPurchased}
                      totalUsed={totalUsed}
                      pending={pending}
                      completed={completed}
                      triggerReload={() => triggerReload()}
                      tourRun={tourRun}
                      setTriggerStep={setTriggerStep}
                      subscriptionData={subscriptionData}
                    />
                  ) : (
                    <React.Fragment
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ClipLoader
                        color={"#398bd6"}
                        loading={loading}
                        css={override}
                        size={150}
                      />
                      <Typo
                        align="center"
                        style={{ marginTop: 10, color: "#398bd6" }}
                      >
                        {t("assignments:checkingForUpdates")}
                      </Typo>
                    </React.Fragment>
                  ),
                },
                {
                  tabName: t("assignments:tabs.tools1"),
                  tabIcon: Tool1Icon,
                  tabContent: !loading ? (
                    <Tool1Tab
                      totalPurchased={totalPurchased}
                      totalUsed={totalUsed}
                      pending={pending}
                      completed={completed}
                      triggerReload={() => triggerReload()}
                      subscriptionData={subscriptionData}
                    />
                  ) : (
                    <React.Fragment>
                      <ClipLoader
                        color={"#398bd6"}
                        loading={loading}
                        css={override}
                        size={150}
                      />
                      <Typo
                        align="center"
                        style={{ marginTop: 10, color: "#398bd6" }}
                      >
                        {t("assignments:checkingForUpdates")}
                      </Typo>
                    </React.Fragment>
                  ),
                },
                {
                  tabName: t("assignments:tabs.tools2"),
                  tabIcon: Tool2Icon,
                  tabContent: !loading ? (
                    <Tool2Tab
                      totalPurchased={totalPurchased}
                      totalUsed={totalUsed}
                      pending={pending}
                      completed={completed}
                      triggerReload={() => triggerReload()}
                      subscriptionData={subscriptionData}
                    />
                  ) : (
                    <React.Fragment>
                      <ClipLoader
                        color={"#398bd6"}
                        loading={loading}
                        css={override}
                        size={150}
                      />
                      <Typo
                        align="center"
                        style={{ marginTop: 10, color: "#398bd6" }}
                      >
                        {t("assignments:checkingForUpdates")}
                      </Typo>
                    </React.Fragment>
                  ),
                },
                {
                  tabName: t("assignments:tabs.tools3"),
                  tabIcon: Tool3Icon,
                  tabContent: !loading ? (
                    <Tool3Tab
                      totalPurchased={totalPurchased}
                      totalUsed={totalUsed}
                      pending={pending}
                      completed={completed}
                      triggerReload={() => triggerReload()}
                      subscriptionData={subscriptionData}
                    />
                  ) : (
                    <React.Fragment>
                      <ClipLoader
                        color={"#398bd6"}
                        loading={loading}
                        css={override}
                        size={150}
                      />
                      <Typo
                        align="center"
                        style={{ marginTop: 10, color: "#398bd6" }}
                      >
                        {t("assignments:checkingForUpdates")}
                      </Typo>
                    </React.Fragment>
                  ),
                },
                {
                  tabName: t("assignments:tabs.tools4"),
                  tabIcon: Tool4Icon,
                  tabContent: !loading ? (
                    <Tool4Tab
                      totalPurchased={totalPurchased}
                      totalUsed={totalUsed}
                      pending={pending}
                      completed={completed}
                      triggerReload={() => triggerReload()}
                      subscriptionData={subscriptionData}
                    />
                  ) : (
                    <React.Fragment>
                      <ClipLoader
                        color={"#398bd6"}
                        loading={loading}
                        css={override}
                        size={150}
                      />
                      <Typo
                        align="center"
                        style={{ marginTop: 10, color: "#398bd6" }}
                      >
                        {t("assignments:checkingForUpdates")}
                      </Typo>
                    </React.Fragment>
                  ),
                },
                {
                  tabName: t("assignments:tabs.tools5"),
                  tabIcon: Tool5Icon,
                  tabContent: !loading ? (
                    <Tool5Tab
                      totalPurchased={totalPurchased}
                      totalUsed={totalUsed}
                      pending={pending}
                      completed={completed}
                      triggerReload={() => triggerReload()}
                      subscriptionData={subscriptionData}
                    />
                  ) : (
                    <React.Fragment>
                      <ClipLoader
                        color={"#398bd6"}
                        loading={loading}
                        css={override}
                        size={150}
                      />
                      <Typo
                        align="center"
                        style={{ marginTop: 10, color: "#398bd6" }}
                      >
                        {t("assignments:checkingForUpdates")}
                      </Typo>
                    </React.Fragment>
                  ),
                },
              ]}
            />
          </GridItem>
        </div>

        {grow ? (
          <Grow in={grow} {...(grow ? { timeout: 400 } : { timeout: 500 })}>
            <div
              style={{
                width: "30%",
                minHeight: `${height}px`,
              }}
            >
              <GridItem>
                <Card
                  className="selected-assessments"
                  style={{
                    height: `${height}px`,
                    transition: "height 0.4s",
                  }}
                >
                  <CardHeader color="success" style={{ padding: 11 }}>
                    <GridContainer
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <GridItem
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <AssignmentTurnedInIcon
                          style={{
                            fontSize: 29,
                            marginRight: -20,
                          }}
                        />
                      </GridItem>
                      <GridItem>
                        <h4
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                          }}
                        >
                          {t("assignments:selectedAssessments")}
                        </h4>
                      </GridItem>
                    </GridContainer>
                  </CardHeader>
                  <CardBody style={{ overflow: "auto" }}>
                    {order.reverse().map((assessment, index) => {
                      let color = "#000"; // Default color
                      // Dynamically setting colors based on assessment type
                      if (assessment[0] === "1") {
                        color = "#bb3737";
                      } else if (assessment[0] === "2") {
                        color = "#ef7e28";
                      } else if (assessment[0] === "3") {
                        color = "#0c8197";
                      } else if (assessment[0] === "4") {
                        color = "#098359";
                      } else if (assessment[0] === "5") {
                        color = "#7f4a7f";
                      }

                      return (
                        <React.Fragment key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              minHeight: 55,
                            }}
                          >
                            {assessment.length > 1 ? (
                              <SvgIcon
                                component={Cog}
                                style={{
                                  fontSize: 18,
                                  marginRight: 10,
                                  color: color,
                                }}
                              />
                            ) : (
                              <SvgIcon
                                component={Module}
                                style={{
                                  fontSize: 18,
                                  marginRight: 10,
                                  color: color,
                                }}
                              />
                            )}
                            <Typography>
                              {t(
                                `assignments:assessmentNamesByNumber.${assessment}`
                              )}
                            </Typography>
                          </div>
                          <Divider />
                        </React.Fragment>
                      );
                    })}
                  </CardBody>
                  <CardFooter
                    elevation={5}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderTop: "4px double #61b865",
                      marginLeft: 0,
                      marginRight: 0,
                      marginBottom: 0,
                      paddingBottom: 15,
                    }}
                  >
                    <Grid
                      container
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Grid item>
                        <Button
                          className="tour-assign-clear"
                          style={{ marginRight: 10, marginLeft: 10 }}
                          color="default"
                          onClick={() => {
                            if (tourRun) {
                              setTriggerStep(-3);
                            }
                            clearAll();
                          }}
                        >
                          {t("assignments:clear")}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          className="tour-assign-button"
                          onClick={() => {
                            confirmationAlert();
                          }}
                          style={{ marginRight: 10, marginLeft: 10 }}
                          color="success"
                        >
                          {t("assignments:assign")}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardFooter>
                </Card>
              </GridItem>
            </div>
          </Grow>
        ) : (
          false
        )}
      </GridContainer>
      <AllAssigned
        assignmentDetails={props.assignmentDetails}
        open={props.openAssigned}
        setOpen={props.setOpenAssigned}
      />
      {alert}
      <AssignLoading open={openAssignLoading} setOpen={setOpenAssignLoading} />
      <CheckLoading open={openCheckLoading} setOpen={setOpenCheckLoading} />
    </div>
  );
}

export default withRouter(AssignAssessments);
