import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography as MUITypography,
  Button as MUIButton,
  Typography,
  Box,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
// @material-ui/icons
import { useTranslation } from "react-i18next";
import { useStripe } from "@stripe/react-stripe-js";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import SchoolIcon from "@material-ui/icons/School";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import SweetAlert from "react-bootstrap-sweetalert";
import CircularProgress from "@material-ui/core/CircularProgress";
import alertstyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  ...alertstyles,
  root: {
    textAlign: "center",
    padding: theme.spacing(4),
  },
  card: {
    borderRadius: 8,
    boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0px 10px 20px rgba(0,0,0,0.2)",
    },
    padding: theme.spacing(4),
    backgroundColor: "#fff",
    height: "100%",
  },
  activeCard: {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.light,
    color: "#fff",
    "& h6, & h4, & p": {
      color: "#fff",
    },
    "& ul li": {
      color: "#fff",
    },
    height: "100%",
  },
  cardHeader: {
    backgroundColor: "#f5f5f5",
    padding: theme.spacing(2),
    borderRadius: "8px 8px 0 0",
    backgroundColor: "inherit",
  },
  cardBody: {
    padding: theme.spacing(2),
  },
  cardFooter: {
    padding: theme.spacing(2),
    borderTop: "1px solid #e0e0e0",
  },
  price: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  subButton: {
    marginTop: theme.spacing(2),
    borderRadius: 50,
    padding: theme.spacing(1, 4),
  },
  icon: {
    fontSize: "60px",
    color: theme.palette.primary.main,
  },
  tierTitle: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  featureList: {
    listStyle: "none",
    padding: 0,
    textAlign: "left",
  },
  featureItem: {
    marginBottom: theme.spacing(1),
    fontSize: "17px",
    display: "flex",
    alignItems: "center",
  },
  featureIcon: {
    marginRight: theme.spacing(1),
    color: "#4caf50",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  expirationNotice: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: "5px",
    border: "1px solid #ff9800",
    backgroundColor: "rgba(255, 152, 0, 0.1)",
    color: "#ff9800",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

function SubscriptionModules() {
  const classes = useStyles();
  const { t } = useTranslation();
  const stripe = useStripe();
  const [activeTier, setActiveTier] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedCurrency, setSelectedCurrency] = useState("usd");
  const [updatingSubscription, setUpdatingSubscription] = useState(false);
  const [alert, setAlert] = useState(null);

  const subscriptionTiers = [
    {
      tier: "student",
      title: t("shop:subscriptions.studentTitle"),
      priceId: process.env.REACT_APP_STUDENT_PRICE_ID,
      icon: <AccountCircleRoundedIcon />,
      price: [
        {
          code: "usd",
          value: t("shop:subscriptions.price", {
            amount: 33,
            currency: t("shop:subscriptions.usd"),
          }),
        },
        {
          code: "eur",
          value: t("shop:subscriptions.price", {
            amount: 30,
            currency: t("shop:subscriptions.eur"),
          }),
        },
        {
          code: "aud",
          value: t("shop:subscriptions.price", {
            amount: 49,
            currency: t("shop:subscriptions.aud"),
          }),
        },
      ],
      units: 200,
    },
    {
      tier: "coach",
      title: t("shop:subscriptions.coachTitle"),
      icon: <SupervisedUserCircleIcon />,
      priceId: process.env.REACT_APP_COACH_PRICE_ID,
      price: [
        {
          code: "usd",
          value: t("shop:subscriptions.price", {
            amount: 66,
            currency: t("shop:subscriptions.usd"),
          }),
        },
        {
          code: "eur",
          value: t("shop:subscriptions.price", {
            amount: 40,
            currency: t("shop:subscriptions.eur"),
          }),
        },
        {
          code: "aud",
          value: t("shop:subscriptions.price", {
            amount: 99,
            currency: t("shop:subscriptions.aud"),
          }),
        },
      ],
      units: 400,
    },
    {
      tier: "school",
      title: t("shop:subscriptions.schoolTitle"),
      icon: <SchoolIcon />,
      priceId: process.env.REACT_APP_SCHOOL_PRICE_ID,
      price: [
        {
          code: "usd",
          value: t("shop:subscriptions.price", {
            amount: 163,
            currency: t("shop:subscriptions.usd"),
          }),
        },
        {
          code: "eur",
          value: t("shop:subscriptions.price", {
            amount: 150,
            currency: t("shop:subscriptions.eur"),
          }),
        },
        {
          code: "aud",
          value: t("shop:subscriptions.price", {
            amount: 244,
            currency: t("shop:subscriptions.aud"),
          }),
        },
      ],
      units: 1000,
    },
    {
      tier: "gym",
      title: t("shop:subscriptions.gymTitle"),
      icon: <FitnessCenterIcon />,
      priceId: process.env.REACT_APP_GYM_PRICE_ID,
      price: [
        {
          code: "usd",
          value: t("shop:subscriptions.price", {
            amount: 326,
            currency: t("shop:subscriptions.usd"),
          }),
        },
        {
          code: "eur",
          value: t("shop:subscriptions.price", {
            amount: 300,
            currency: t("shop:subscriptions.eur"),
          }),
        },
        {
          code: "aud",
          value: t("shop:subscriptions.price", {
            amount: 488,
            currency: t("shop:subscriptions.aud"),
          }),
        },
      ],
      units: 2000,
    },
  ];

  const handleCardClick = (tier) => {
    setActiveTier(tier);
  };

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  // Process subscription update after confirmation
  const processSubscriptionUpdate = async (priceId, subscription) => {
    try {
      setUpdatingSubscription(true);
      const response = await axios.post(
        "/api/stripe/update-subscription",
        {
          lineItems: [
            {
              price: priceId,
              quantity: 1,
            },
          ],
          currency: selectedCurrency,
          customer: subscription.customerId,
          subscription: subscription.subscriptionId,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      // If we got a sessionId, redirect to checkout
      if (response.data.sessionId) {
        const { error } = await stripe.redirectToCheckout({
          sessionId: response.data.sessionId,
        });

        if (error) {
          console.log(error);
          setUpdatingSubscription(false);
        }
      } else {
        // Direct update was successful, update UI
        const tierObj = subscriptionTiers.find((t) => t.priceId === priceId);
        if (tierObj) {
          setActiveTier(tierObj.tier);
        }

        // Reload subscription details
        axios
          .get("/api/user/get-subscription-details", {
            headers: { "auth-token": localStorage.getItem("token") },
          })
          .then((res) => {
            // Important: Update the subscription details state with the new data
            // This will also clear any cancelation status
            const updatedSubscription = res.data;

            // Clear cancellation status if it exists
            if (updatedSubscription.cancelAtPeriodEnd) {
              // Create a copy of the subscription data with cancelation info removed
              const cleanedSubscription = {
                ...updatedSubscription,
                cancelAtPeriodEnd: false,
                expirationDate: null,
              };
              setSubscriptionDetails(cleanedSubscription);
            } else {
              setSubscriptionDetails(updatedSubscription);
            }
          });

        setUpdatingSubscription(false);
        setAlert(
          <SweetAlert
            success
            title={t("shop:subscriptions.updateSuccess") || "Success!"}
            onConfirm={() => setAlert(null)}
            confirmBtnCssClass={classes.button + " " + classes.success}
            confirmBtnText="OK"
            showConfirm={true}
            allowEscape={true}
            closeOnClickOutside={true}
          >
            {t("shop:subscriptions.updateSuccessMessage") ||
              "Your subscription has been updated successfully!"}
          </SweetAlert>
        );
      }
    } catch (error) {
      console.error("Stripe checkout error:", error);
      setUpdatingSubscription(false);

      // Show error alert
      setAlert(
        <SweetAlert
          error
          title={t("error") || "Error"}
          onConfirm={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText="OK"
          showConfirm={true}
          allowEscape={true}
          closeOnClickOutside={true}
        >
          {error.message ||
            t("shop:subscriptions.updateError") ||
            "An error occurred while updating your subscription."}
        </SweetAlert>
      );
    }
  };

  // Initial checkout handler
  const handleCheckout = async (priceId, subscription) => {
    try {
      const selectedTier = subscriptionTiers.find((t) => t.priceId === priceId);
      const tierName = selectedTier ? selectedTier.title : "";

      // If they have an active subscription, show confirmation first
      if (subscription.subscriptionId && subscription.active) {
        setAlert(
          <SweetAlert
            warning
            showCancel
            confirmBtnText={t("common:confirm") || "Confirm"}
            confirmBtnCssClass={classes.button + " " + classes.success}
            cancelBtnText={t("common:cancel") || "Cancel"}
            cancelBtnCssClass={classes.button + " " + classes.danger}
            title={
              t("shop:subscriptions.confirmSwitchTitle") ||
              "Change Subscription?"
            }
            onConfirm={() => {
              setAlert(null);
              processSubscriptionUpdate(priceId, subscription);
            }}
            onCancel={() => setAlert(null)}
            allowEscape={true}
            closeOnClickOutside={true}
          >
            {t("shop:subscriptions.confirmSwitchMessage", { tierName }) ||
              `Are you sure you want to switch to the ${tierName} plan? You will be charged immediately and the new units will be added to your available units.`}
          </SweetAlert>
        );
      } else {
        // For new subscriptions, no confirmation needed
        setUpdatingSubscription(true);
        const response = await axios.post(
          "/api/stripe/create-checkout-session",
          {
            ...(!!subscription.customerId
              ? { customer: subscription.customerId }
              : {}),
            lineItems: [
              {
                price: priceId,
                quantity: 1,
              },
            ],
            currency: selectedCurrency,
            mode: "subscription",
          },
          {
            headers: {
              "auth-token": localStorage.getItem("token"),
            },
          }
        );
        const { sessionId } = response.data;
        const { error } = await stripe.redirectToCheckout({
          sessionId,
        });

        if (error) {
          console.log(error);
          setUpdatingSubscription(false);
        }
      }
    } catch (error) {
      console.error("Stripe checkout error:", error);
      setUpdatingSubscription(false);

      // Show error alert
      setAlert(
        <SweetAlert
          error
          title={t("error") || "Error"}
          onConfirm={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText="OK"
          showConfirm={true}
          allowEscape={true}
          closeOnClickOutside={true}
        >
          {error.message ||
            t("shop:subscriptions.checkoutError") ||
            "An error occurred while processing your request."}
        </SweetAlert>
      );
    }
  };

  const cancelSubscription = async (subscription) => {
    try {
      setAlert(
        <SweetAlert
          warning
          showCancel
          confirmBtnText={t("common:confirm") || "Confirm"}
          confirmBtnCssClass={classes.button + " " + classes.danger}
          cancelBtnText={t("common:cancel") || "Cancel"}
          cancelBtnCssClass={classes.button + " " + classes.warning}
          title={
            t("shop:subscriptions.confirmCancelTitle") || "Cancel Subscription?"
          }
          onConfirm={() => {
            setAlert(null);
            processCancellation(subscription);
          }}
          onCancel={() => setAlert(null)}
          allowEscape={true}
          closeOnClickOutside={true}
        >
          {t("shop:subscriptions.confirmCancelMessage") ||
            "Are you sure you want to cancel your subscription? You will still have access to your remaining units until the end of your billing period."}
        </SweetAlert>
      );
    } catch (error) {
      console.error("Error setting up cancellation dialog:", error);
    }
  };

  // Function to process the actual cancellation
  const processCancellation = async (subscription) => {
    try {
      setUpdatingSubscription(true);

      const response = await axios.post(
        "/api/stripe/cancel-subscription",
        {
          subscription: subscription.subscriptionId,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      // Update UI after successful cancellation
      if (response.data.success) {
        // Don't set activeTier to null since they still have access
        // Just reload subscription details to get the expirationDate
        axios
          .get("/api/user/get-subscription-details", {
            headers: { "auth-token": localStorage.getItem("token") },
          })
          .then((res) => {
            setSubscriptionDetails(res.data);
          });

        // Show success message
        setAlert(
          <SweetAlert
            success
            title={
              t("shop:subscriptions.cancelSuccess") || "Subscription Cancelled"
            }
            onConfirm={() => setAlert(null)}
            confirmBtnCssClass={classes.button + " " + classes.success}
            confirmBtnText="OK"
            showConfirm={true}
            allowEscape={true}
            closeOnClickOutside={true}
          >
            {t("shop:subscriptions.cancelSuccessMessage") ||
              "Your subscription has been cancelled successfully. You'll still have access until the end of your billing period."}
          </SweetAlert>
        );
      }

      setUpdatingSubscription(false);
    } catch (error) {
      console.error("Subscription cancellation error:", error);
      setUpdatingSubscription(false);

      // Show error alert
      setAlert(
        <SweetAlert
          error
          title={t("error") || "Error"}
          onConfirm={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText="OK"
          showConfirm={true}
          allowEscape={true}
          closeOnClickOutside={true}
        >
          {error.message ||
            t("shop:subscriptions.cancelError") ||
            "An error occurred while cancelling your subscription."}
        </SweetAlert>
      );
    }
  };

  // Check if a subscription is still valid based on expiration date
  const isSubscriptionValid = (subscription) => {
    if (!subscription || !subscription.active) return false;

    if (subscription.cancelAtPeriodEnd && subscription.expirationDate) {
      const expirationDate = new Date(subscription.expirationDate);
      const now = new Date();
      return expirationDate > now;
    }

    return subscription.active;
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/user/get-subscription-details", {
        headers: { "auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const subscription = res.data;
        setSubscriptionDetails(subscription);

        // Check if subscription is valid (active and not expired)
        if (isSubscriptionValid(subscription)) {
          const provisions = subscription.provisions;
          const currentlyActiveProvision = provisions[provisions.length - 1];
          setActiveTier(currentlyActiveProvision?.type || null);
        } else {
          setActiveTier(null);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    try {
      const date = new Date(dateString);
      // Use a more direct approach to formatting the date
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    } catch (e) {
      console.error("Error formatting date:", e);
      return dateString; // Return the original string if there's an error
    }
  };

  return (
    <div className={classes.root}>
      {alert}
      <MUITypography variant="h4" gutterBottom>
        {t("shop:subscriptions.pricingPlans")}
      </MUITypography>
      <FormControl
        variant="outlined"
        style={{
          marginBottom: "20px",
          backgroundColor: "white",
          minWidth: "100px",
        }}
      >
        <InputLabel id="currency-select-label">
          {t("shop:subscriptions.currency")}
        </InputLabel>
        <Select
          labelId="currency-select-label"
          id="currency-select"
          value={selectedCurrency}
          onChange={handleCurrencyChange}
          label={t("shop:subscriptions.currency")}
        >
          {["usd", "eur", "aud"].map((currency) => (
            <MenuItem key={currency} value={currency}>
              {currency.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid container spacing={4} justify="center">
        {subscriptionTiers.map(
          ({ tier, title, icon, price, units, priceId }) => (
            <Grid item xs={12} lg={3} md={6} key={tier}>
              <div
                className={`${classes.card} ${
                  activeTier === tier ? classes.activeCard : ""
                }`}
              >
                <div className={classes.cardHeader}>
                  {icon}
                  <MUITypography className={classes.tierTitle} variant="h6">
                    {title}
                  </MUITypography>
                  <MUITypography className={classes.price}>
                    {price.find((p) => p.code === selectedCurrency).value}
                  </MUITypography>
                </div>
                <div className={classes.cardBody}>
                  <ul className={classes.featureList}>
                    <li className={classes.featureItem}>
                      <CheckIcon className={classes.featureIcon} />
                      <Typography variant="body1">
                        {t("shop:subscriptions.accessTo")}{" "}
                        <strong>{units}</strong>{" "}
                        {t("shop:subscriptions.unitsPerMonth")}
                      </Typography>
                    </li>

                    {/* Show expiration notice for canceled subscriptions */}
                    {subscriptionDetails.cancelAtPeriodEnd &&
                      subscriptionDetails.expirationDate &&
                      activeTier === tier && (
                        <li className={classes.featureItem}>
                          <div className={classes.expirationNotice}>
                            <Typography
                              variant="body2"
                              style={{ color: "inherit" }}
                            >
                              {t(
                                "shop:subscriptions.planCanceled",
                                "Subscription Canceled"
                              )}
                              <br />
                              {t(
                                "shop:subscriptions.accessUntilPrefix",
                                "Access until"
                              )}{" "}
                              {formatDate(subscriptionDetails.expirationDate)}
                            </Typography>
                          </div>
                        </li>
                      )}

                    <Box
                      border={"1px solid"}
                      mt={2}
                      p={1}
                      style={{ borderRadius: "5px", borderColor: "#f0f0f0" }}
                    >
                      <Typography
                        variant="body1"
                        align="center"
                        color="primary"
                      >
                        {t("shop:subscriptions.rolloverUnits")}
                      </Typography>
                    </Box>
                  </ul>
                </div>
                <div className={classes.cardFooter}>
                  {loading || updatingSubscription ? (
                    <div className={classes.loader}>
                      <CircularProgress />
                    </div>
                  ) : activeTier === tier ? (
                    <div className={classes.buttonContainer}>
                      <MUIButton
                        variant="contained"
                        color="primary"
                        disabled={true}
                        className={classes.subButton}
                        style={{ width: "100%" }}
                      >
                        {subscriptionDetails.cancelAtPeriodEnd
                          ? t("shop:subscriptions.canceledPlan") ||
                            "Plan Canceled"
                          : t("shop:subscriptions.currentPlan")}
                      </MUIButton>

                      {/* Only show cancel button if not already canceled */}
                      {!subscriptionDetails.cancelAtPeriodEnd && (
                        <MUIButton
                          variant="outlined"
                          className={classes.subButton}
                          style={{ width: "100%" }}
                          onClick={() =>
                            cancelSubscription(subscriptionDetails)
                          }
                        >
                          {t("shop:subscriptions.cancelPlan") || "Cancel"}
                        </MUIButton>
                      )}
                    </div>
                  ) : (
                    <MUIButton
                      variant="contained"
                      color="primary"
                      disabled={updatingSubscription}
                      className={classes.subButton}
                      onClick={() =>
                        handleCheckout(priceId, subscriptionDetails)
                      }
                    >
                      {t("shop:subscriptions.subscribe")}
                    </MUIButton>
                  )}
                </div>
              </div>
            </Grid>
          )
        )}
      </Grid>
    </div>
  );
}

export default SubscriptionModules;
