import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  LinearProgress,
  Typography,
  Box,
  Grid,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import axios from "axios";
import WarningIcon from "@material-ui/icons/Warning";

const SubscriptionStatus = ({ triggerRefetchSubscription }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/client/get-subscription-info", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setSubscriptionData(response.data);
        setError(null);
      })
      .catch((err) => {
        console.error("Error fetching subscription data:", err);
        setError(t("subscription:errorFetchingData"));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [t, triggerRefetchSubscription]);

  // Show loading state
  if (loading) {
    return (
      <Box className={classes.loading}>
        <CircularProgress size={24} className={classes.loadingSpinner} />
        <Typography variant="body2">{t("subscription:loading")}</Typography>
      </Box>
    );
  }

  // Show error state
  if (error) {
    return <Box className={classes.error}>{error}</Box>;
  }

  // Calculate percentage used
  const totalUnits = subscriptionData?.totalUnits || 0;
  const totalUsed = subscriptionData?.totalUsed || 0;
  const totalAvailable = subscriptionData?.totalAvailable || 0;
  const percentUsed =
    totalUnits > 0 ? Math.round((totalUsed / totalUnits) * 100) : 0;

  const percentUsedDisplay =
    totalUnits > 0
      ? (totalUsed / totalUnits) * 100 < 1 && (totalUsed / totalUnits) * 100 > 0
        ? "<1"
        : Math.round((totalUsed / totalUnits) * 100)
      : 0;

  // Determine color based on usage
  const getStatusColor = (percent) => {
    if (percent >= 90) return "#f44336"; // red
    if (percent >= 70) return "#ff9800"; // amber
    return "#4caf50"; // green
  };

  // Format date to readable format
  const formatDate = (dateString) => {
    if (!dateString) return t("common:notAvailable");
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString(t("common:locale") || undefined, {
        year: "numeric",
        month: "short",
        day: "numeric",
        timeZone: "UTC",
      });
    } catch (e) {
      console.error("Error formatting date:", e);
      return dateString;
    }
  };

  if (!subscriptionData || !subscriptionData.active) {
    return (
      <Box className={classes.warning}>
        {t("subscription:noActiveSubscription")}
      </Box>
    );
  }

  const statusColor = getStatusColor(percentUsed);
  const latestProvision =
    subscriptionData.provisions && subscriptionData.provisions.length > 0
      ? subscriptionData.provisions[subscriptionData.provisions.length - 1]
      : null;

  // Check if subscription is canceled
  const isCanceled =
    subscriptionData.cancelAtPeriodEnd && subscriptionData.expirationDate;

  return (
    <Box className={classes.root}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant="body1" className={classes.statusText}>
            <Box
              className={classes.statusIndicator}
              style={{ backgroundColor: statusColor }}
            />
            {t("subscription:subscriptionUnits")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            <span className={classes.valueText}>{totalAvailable}</span>{" "}
            {t("subscription:available")}
          </Typography>
        </Grid>
      </Grid>

      <LinearProgress
        variant="determinate"
        value={percentUsed}
        className={classes.progressBar}
        style={{
          backgroundColor: "#e0e0e0",
          "& .MuiLinearProgress-bar": { backgroundColor: statusColor },
        }}
      />

      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="body2" className={classes.subText}>
            {t("subscription:used")}: {totalUsed}/{totalUnits} (
            {percentUsedDisplay}%)
          </Typography>
        </Grid>
        <Grid item>
          {latestProvision && (
            <Typography variant="body2" className={classes.subText}>
              {t("subscription:lastProvision")}:{" "}
              {formatDate(latestProvision.date)}
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* Cancellation notice */}
      {isCanceled && (
        <>
          <Divider className={classes.divider} />
          <Box className={classes.cancellationBox}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <WarningIcon className={classes.warningIcon} />
              </Grid>
              <Grid item xs>
                <Typography
                  variant="body2"
                  className={classes.cancellationText}
                >
                  {t("subscription:planCanceled") || "Subscription Canceled"}
                </Typography>
                <Typography variant="body2" className={classes.expirationText}>
                  {t("subscription:accessUntilPrefix") || "Access until"}{" "}
                  <strong>{formatDate(subscriptionData.expirationDate)}</strong>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
  },
  statusIndicator: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    marginRight: theme.spacing(1),
  },
  progressBar: {
    height: 8,
    borderRadius: 4,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  statusText: {
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
  },
  valueText: {
    fontWeight: 600,
  },
  subText: {
    color: theme.palette.text.secondary,
    fontSize: "0.75rem",
  },
  warning: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.warning.dark,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    fontSize: "0.875rem",
  },
  loading: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    minHeight: 80,
  },
  loadingSpinner: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    fontSize: "0.875rem",
  },
  divider: {
    margin: theme.spacing(1.5, 0),
  },
  cancellationBox: {
    backgroundColor: "rgba(255, 152, 0, 0.08)",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  warningIcon: {
    color: theme.palette.warning.main,
    fontSize: 20,
  },
  cancellationText: {
    fontWeight: 500,
    color: theme.palette.warning.dark,
  },
  expirationText: {
    color: theme.palette.text.secondary,
    fontSize: "0.75rem",
  },
}));

export default SubscriptionStatus;
